import api from "@/base/utils/request";

// 获取审核列表
export const checkList = data => {
  return api({
    url: "/admin/cyc/CompanyCheck/index",
    method: "post",
    data
  });
};

// 审核通过
export const Checked = data => {
  return api({
    url: "/admin/cyc/CompanyCheck/check",
    method: "post",
    data
  });
};

//审核不通过
export const noChecked = data => {
  return api({
    url: "/admin/cyc/CompanyCheck/nocheck",
    method: "post",
    data
  });
};