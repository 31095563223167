<template>
  <el-dialog title="不通过审核" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('reasonContent')">
    <el-form class="medium-form" size="medium" :model="_data" :rules="rules"
             ref="reasonContent" @submit.native.prevent="()=>{}" label-width="65px">
      <el-form-item prop="reasonContent" style="margin-bottom: 0" label="原因：">
        <el-input type="textarea" v-model="reasonContent" style="min-height: 120px;"
                  :maxlength="20" show-word-limit placeholder="请输入内容" />
        <p class="info"><i class="el-icon-info" />将通过订阅消息、系统消息发送通知</p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false" size="small">取消</el-button>
      <el-button type="primary" size="small" @click="submit('reasonContent')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { noChecked } from "../../api/examine/examine-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    IDs: {
      type: Array,
    },
    type: {
      type: Number,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        reasonContent: [
          {required: true, message: "请输入不通过原因", trigger: "blur"},
          { validator: this.reasonContentPass, trigger: "blur" }
          ],
      },
      reasonContent: "",
    };
  },
  methods: {
    // 验证内容
    reasonContentPass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入不通过原因"));
      } else {
        callback();
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {};
          data.reason = this.reasonContent;
          data.id = this.IDs;
          data.ent_check_type = this.type;
          noChecked(data)
            .then((res) => {
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style>
</style>
