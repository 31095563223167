<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="企业名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item v-if="form.tab !== 'assoc'" label="申请人：" prop="user_nickname">
          <el-input v-model="form.user_nickname" placeholder="请输入申请人名称"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="提交时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      stockArr: [
        { status: 0, name: "未上市" },
        { status: 1, name: "已上市" },
      ],
      identArr: [
        { status: 0, name: "待认证" },
        { status: 1, name: "已认证" },
      ],
      categoryArr: [],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>