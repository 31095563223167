<template>
  <div class="list-page">
    <list-filter :filterForm="filterForm" :uploadFilter="ok" />
    <list-tabs
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleClick"
      v-model="filterForm.tab"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button
          @click="handlePass(1, filterForm.tab)"
          size="small"
          type="success"
          >通过</el-button
        >
        <el-button
          @click="handlePass(0, filterForm.tab)"
          size="small"
          type="warning"
          >不通过</el-button
        >
      </div>
      <el-table
        :data="tbody"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        class="thead-light"
        stripe
        style="width: 100%"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                @click="PassRow(scope.row, 1, filterForm.tab)"
                size="small"
                type="text"
              >
                通过
              </el-button>
              <el-button
                @click="PassRow(scope.row, 0, filterForm.tab)"
                size="small"
                type="text"
                >不通过
              </el-button>
              <el-button
                @click="
                  toDetail(
                    filterForm.tab !== 'assoc'
                      ? scope.row.id
                      : scope.row.company_id
                  )
                "
                size="small"
                type="text"
                >查看</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <el-table-column
          :key="index"
          :label="th.label"
          :min-width="th.minWidth || ''"
          :prop="th.prop"
          :sortable="th.sortable"
          show-overflow-tooltip
          v-for="(th, index) in thead[filterForm.tab]"
        >
          <template slot-scope="scope">
            <!-- 状态 -->
            <p v-if="th.prop === 'status_check_text'">
              <span
                :class="[
                  'status-dot',
                  scope.row[th.prop] === '审核中' ? 'yellow' : 'green',
                ]"
              ></span>
              {{ scope.row[th.prop] }}
            </p>
            <!-- 营业执照 -->
            <el-image
              :src="scope.row[th.prop]"
              @click="$previewImage([scope.row[th.prop]])"
              class="list-image"
              fit="contain"
              v-else-if="th.prop === 'check_image'"
            ></el-image>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :change="changPage"
      :page="pageData.current_page"
      :total="pageData.total"
    />
    <ReasonDialog
      :IDs="ids"
      :type="current_check_type"
      :updateList="reflashList"
      v-model="openReason"
    />
  </div>
</template>

<script>
import listFilter from '../../../components/Examine/ListFilter'
import Pagination from '@/base/components/Default/Pagination'
import ReasonDialog from '../../../components/Examine/ReasonDialog'
import { checkList, Checked } from '../../../api/examine/examine-list'
export default {
  data() {
    return {
      tabArr: [
        { label: '新增企业', name: 'new', badge: 1, url: 'cyc_company_new' },
        { label: '关联审核', name: 'assoc', badge: 1, url: 'cyc_company_assoc' },
        { label: '企业认证', name: 'ident', badge: 1, url: 'cyc_company_ident' },
        // { label: "取消认证", name: "back" },
      ],
      loading: false, //加载
      thead: {
        new: [
          { label: '企业ID', prop: 'id', minWidth: 80 },
          { label: '企业名称', prop: 'name', minWidth: 150 },
          { label: '申请人', prop: 'user_nickname', minWidth: 110 },
          { label: '会内职务', prop: 'user_title_name', minWidth: 150 },
          { label: '审核状态', prop: 'status_check_text', minWidth: 100 },
          {
            label: '提交时间',
            prop: 'create_time',
            minWidth: 160,
            sortable: true,
          },
        ],
        ident: [
          { label: '企业ID', prop: 'id', minWidth: 80 },
          { label: '企业名称', prop: 'name', minWidth: 150 },
          { label: '申请人', prop: 'user_nickname', minWidth: 110 },
          { label: '会内职务', prop: 'user_title_name', minWidth: 150 },
          { label: '营业执照', prop: 'check_image', minWidth: 100 },
          { label: '审核状态', prop: 'status_check_text', minWidth: 100 },
          {
            label: '提交时间',
            prop: 'create_time',
            minWidth: 160,
            sortable: true,
          },
        ],
        back: [
          { label: '企业ID', prop: 'id', minWidth: 80 },
          { label: '企业名称', prop: 'name', minWidth: 150 },
          { label: '取消原因', prop: 'reason', minWidth: 200 },
          { label: '申请人', prop: 'user_nickname', minWidth: 110 },
          { label: '会内职务', prop: 'manage_title_name', minWidth: 150 },
          { label: '审核状态', prop: 'status_check_text', minWidth: 100 },
          {
            label: '提交时间',
            prop: 'create_time',
            minWidth: 160,
            sortable: true,
          },
        ],
        assoc: [
          { label: '企业ID', prop: 'company_id', minWidth: 80 },
          { label: '关联企业', prop: 'assoc_name', minWidth: 150 },
          { label: '申请人', prop: 'nickname', minWidth: 110 },
          { label: '会内职务', prop: 'title_name', minWidth: 140 },
          { label: '审核状态', prop: 'status_check_text', minWidth: 100 },
          {
            label: '提交时间',
            prop: 'create_time',
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: '', //企业名称
        user_nickname: '',
        tab: 'new', //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      //筛选缓存
      filters: {
        new: null,
        ident: null,
        back: null,
        assoc: null,
      },
      pageData: {
        page_size: 15,
        tab: 'new',
      },
      current_check_type: 0,
      ids: [], // 不通过组件绑定数组
      openReason: false,
      tbody: [],
      selectArr: [], //勾选的数组id
    }
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        name: 'CompanyExamineDetail',
        params: { type: 'view', id: id },
      })
    },
    // 获取审核列表
    getCheckList(pageData) {
      this.tbody = []
      this.pageData = {}
      this.loading = true
      checkList(pageData)
        .then((res) => {
          const { data } = res
          this.tbody = data.data
          this.pageData = data
          this.filters[data.tab] = { ...this.filterForm }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 切换tab栏
    handleClick(e) {
      console.log(e.name)
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name]
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: '', //企业名称
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        }
      }
      this.getCheckList(this.filterForm)
    },
    // 批量通过/不通过
    handlePass(status, tab) {
      if (this.selectArr.length) {
        switch (tab) {
          case 'new':
            if (status === 1) {
              this.CheckedDialog(this.selectArr, 1, 'more')
            } else {
              this.UnCheckedDialog(this.selectArr, 1)
            }
            break
          case 'ident':
            if (status === 1) {
              this.CheckedDialog(this.selectArr, 2, 'more')
            } else {
              this.UnCheckedDialog(this.selectArr, 2)
            }
            break
          case 'back':
            if (status === 1) {
              this.CheckedDialog(this.selectArr, 3, 'more')
            } else {
              this.UnCheckedDialog(this.selectArr, 3)
            }
            break
          case 'assoc':
            if (status === 1) {
              this.CheckedDialog(this.selectArr, 4, 'more')
            } else {
              this.UnCheckedDialog(this.selectArr, 4)
            }
            break
        }
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表通过/不通过
    PassRow(row, status, tab) {
      switch (tab) {
        case 'new':
          if (status === 1) {
            this.CheckedDialog([row.id], 1, 'single')
          } else {
            this.UnCheckedDialog([row.id], 1)
          }
          break
        case 'ident':
          if (status === 1) {
            this.CheckedDialog([row.id], 2, 'single')
          } else {
            this.UnCheckedDialog([row.id], 2)
          }
          break
        case 'back':
          if (status === 1) {
            this.CheckedDialog([row.id], 3, 'single')
          } else {
            this.UnCheckedDialog([row.id], 3)
          }
          break
        case 'assoc':
          if (status === 1) {
            this.CheckedDialog([row.id], 4, 'single')
          } else {
            this.UnCheckedDialog([row.id], 4)
          }
          break
      }
    },
    //审核通过弹窗
    CheckedDialog(id, type, key) {
      if (key === 'more') {
        this.$msgbox
          .confirm('是否通过选中数据？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.pass({ id, ent_check_type: type })
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('是否通过该数据？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.pass({ id, ent_check_type: type })
          })
          .catch((err) => {})
      }
    },
    //审核不通过弹窗
    UnCheckedDialog(ids, type) {
      this.ids = ids
      this.current_check_type = type
      this.openReason = true
    },
    //审核通过操作
    pass(data) {
      this.loading = true
      Checked(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.reflashList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.getCheckList(this.filterForm)
    },
    // 筛选回调
    ok(e) {
      const pageData = {}
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getCheckList({ ...this.filterForm, ...pageData })
    },
    // 刷新列表
    reflashList() {
      this.getCheckList({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    // 分页查询
    changPage(e) {
      console.log(e)
      this.filterForm.page_size = e.page_size
      const pageData = { ...this.filterForm, ...e }
      this.getCheckList(pageData)
    },
  },
  created() {
    const requestData = this.$route.query?.tab
      ? { ...this.filterForm, ...this.$route.query }
      : this.filterForm
    this.filterForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.filterForm.tab
    this.getCheckList(requestData) //获取列表数据
  },
  components: { listFilter, Pagination, ReasonDialog },
}
</script>

<style lang="scss" scoped>
.list-image {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>
